import {translate} from "../../../utils/utils";
import {DEFECT_SYNC_DIRECTION} from "../SettingsEnums";
import {
    InternPartDivider,
    SettingsDescriptionWithMarginTop,
} from "../SettingsComponents";
import Box from "@mui/material/Box";
import React from "react";
import PrincipalSyncConfiguration from "./PrincipalSyncConfiguration";
import {PrincipalSyncConfigurationType, SubcontractorSyncConfigurationType} from "./types/types";
import SubcontractorSyncConfiguration from "./SubcontractorSyncConfiguration";
import {useChangeSyncDirectionHandler} from "./hooks/useChangeSynceDirectionHandler";
import {usePrincipalDataHandler} from "./hooks/usePrincipalDataHandler";
import {useSubcontractorDataHandler} from "./hooks/useSubcontractorDataHandler";
import SyncDirectionControl from "./SyncDirectionControl";


type SyncConfiguratorProps = {
    updateProjectSettings: (settings: PrincipalSyncConfigurationType | SubcontractorSyncConfigurationType) => void;
    setRef: (name: string, node: HTMLElement | null | unknown) => void;
    isResetTriggered: boolean;
}

const SyncConfigurator = ({
                              updateProjectSettings,
                              setRef,
                              isResetTriggered
                          }: SyncConfiguratorProps) => {

    const [defectSyncDirection, handleDirectionChange] = useChangeSyncDirectionHandler(isResetTriggered);
    const [subcontractors, subcontractorData, handleSubcontractorDataChange, deleteSubcontractorSync] = useSubcontractorDataHandler(updateProjectSettings, isResetTriggered);
    const [principalData, handlePrincipalDataChange] = usePrincipalDataHandler(updateProjectSettings, isResetTriggered);

    return (
        <div>
            <Box
                aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                ref={node => setRef("projectSettingsExtendedDefectSync", node)}>

                <SyncDirectionControl defectSyncDirection={defectSyncDirection} handleDirectionChange={handleDirectionChange}/>
                {defectSyncDirection === DEFECT_SYNC_DIRECTION.TO_PRINCIPAL && <PrincipalSyncConfiguration defectSyncToPrincipalData={principalData} setDefectSyncToPrincipalData={handlePrincipalDataChange}/>}
                {defectSyncDirection === DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR && <SubcontractorSyncConfiguration deleteSubcontractorSync={deleteSubcontractorSync} defectSyncToSubcontractorData={subcontractorData} updateDefectSyncToSubcontractorData={handleSubcontractorDataChange} subcontractors={subcontractors}/>}
                <SettingsDescriptionWithMarginTop>{translate("settings.defectSync.hint")}</SettingsDescriptionWithMarginTop>
                <InternPartDivider/>
            </Box>
        </div>
    )
}

export default SyncConfigurator;
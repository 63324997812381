import {useEffect, useState} from "react";
// @ts-ignore
import cloneDeep from "lodash/cloneDeep";
import {
    PrincipalSyncConfigurationType,
    Subcontractor,
    SubcontractorSync,
    SubcontractorSyncConfigurationType,
    SubcontractorSyncInfo
} from "../types/types";
import {getSubcontractorData} from "../api/subcontractorData";
import {useAppSelector} from "../../../../store/hooks";
import {CommonStoreStateKeys} from "../../../../store/slices/commonStoreSlice";


export const useSubcontractorDataHandler = (
    updateProjectSettings: (settings: PrincipalSyncConfigurationType | SubcontractorSyncConfigurationType) => void,
    resetTriggered:boolean
) => {

    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);

    const [subcontractors, setSubcontractors] = useState<Subcontractor[]>([])
    const [subcontractorData, setSubcontractorData] = useState<SubcontractorSync>([])
    const [initialSubcontractorData, setInitialSubcontractorData] = useState<SubcontractorSync>([])

    useEffect(() => {
        getSubcontractorData().then((res) => {
            const data = res.data.data;
            const r = data.filter((s:Subcontractor) => s.projects.includes(currentProject.id))
            setSubcontractors(r)
        });
    }, []);

    useEffect(():void => {
        if (subcontractors.some((subcontractor: Subcontractor):boolean => !!subcontractor.defectSync)) {
            let subcontractorWithDefectSync: Subcontractor[] = subcontractors.filter((subcontractor: Subcontractor) => !!subcontractor.defectSync);
            let defectSyncToSubcontractorData: SubcontractorSync = subcontractorWithDefectSync.map((s: Subcontractor): SubcontractorSyncInfo => {
                return {
                    subcontractorId: s.id,
                    defectSyncText: s.defectSync
                };
            });
            setSubcontractorData(defectSyncToSubcontractorData)
            setInitialSubcontractorData(defectSyncToSubcontractorData)
        }else {
            setSubcontractorData([])
            setInitialSubcontractorData([])
        }
    }, [subcontractors]);

    useEffect(() => {
        if(resetTriggered){
            setSubcontractorData(initialSubcontractorData)
        }
    }, [resetTriggered]);

    const handleSubcontractorDataChange = (oldSubcontractorId?: string, newSubcontractorId?: string, text?: string) => {

        let newData:SubcontractorSync = cloneDeep(subcontractorData);
        if (!!oldSubcontractorId && !!newSubcontractorId && oldSubcontractorId === newSubcontractorId) {
            let data:SubcontractorSyncInfo|undefined = newData.find((s: SubcontractorSyncInfo) => s.subcontractorId === oldSubcontractorId);
            if(data && text != null) {
                data.defectSyncText = text;
            }
        } else {
            newData = newData.filter((s: SubcontractorSyncInfo) => s.subcontractorId !== oldSubcontractorId);
            if (!!newSubcontractorId) {
                newData.push({subcontractorId: newSubcontractorId, defectSyncText: ""});
            } else {
                if (!oldSubcontractorId) {
                    let notSelectedSubcontractors = subcontractors.filter((s: Subcontractor) => newData.every((data: SubcontractorSyncInfo) => data.subcontractorId !== s.id));
                    if (notSelectedSubcontractors.length > 0) {
                        newData.push({subcontractorId: notSelectedSubcontractors[0].id, defectSyncText: ""});
                    }
                }
            }
        }
        setSubcontractorData(newData);
        updateProjectSettings({subcontractorSync: newData})
    }

    const deleteSubcontractorSync = (subcontractorId: string) =>{
        let res = subcontractorData.filter(r => r.subcontractorId !== subcontractorId);

        setSubcontractorData(res);

        updateProjectSettings({
            subcontractorSync: [...res, { subcontractorId, defectSyncText: "" }]
        });
    }

    return [subcontractors, subcontractorData, handleSubcontractorDataChange, deleteSubcontractorSync] as const;
};
import {useAppSelector} from "../store/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import {PickProjectPage} from "@bau/material";
import PageContainer, {setDialog} from "../components/PageContainer";
import {getBackboneModel} from "../utils/backboneCommunicationUtils";
import {getProjectIdFromCustomerProject, setErrorDialogWithCloseCallback, translate} from "../utils/utils";

const ProjectSelection = () => {
    let navigate = useNavigate();

    let user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    let accessibleAccounts = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.accessibleAccounts]);
    let projects = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.projects]);
    let currentCustomer = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    const forcedCustomerProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.forcedCustomerProject]);
    const forcedProject = getProjectIdFromCustomerProject(forcedCustomerProject);
    const projectAutoSelect = !!user && !!user.settings && user.settings.projectAutoSelect;
    const {state} = useLocation();

    const tryToFindQueueProject = (queueProject:string, projectFoundCallback:()=>void) => {
        if(!!queueProject && projects.some((p:any) => p.id === queueProject)) {
            projectFoundCallback();
        } else {
            setDialog({
                title: translate("warn"),
                text: translate("currentcustomerhasnotqueueproject").replace("%s", queueProject),
                buttons: [
                    {
                        label: translate("ok"),
                        callback: ()=>{
                            setDialog(null);
                            navigate('/account');
                        }
                    }
                ]
            });
        }
    }

    useEffect(()=>{
        if(!!state && state.customerProjectAutoSelect) {
            if(!!forcedProject) {
                onPickProject(forcedProject);
                return;
            }

            if(!projectAutoSelect) {
                return;
            }

            let offlineQueue = getBackboneModel(CommonStoreStateKeys.offlineQueue);
            offlineQueue.queue.getSize((size:any)=>{
                if (size > 0) {
                    offlineQueue.queue.getProject((queueProject:any)=> {
                        tryToFindQueueProject(queueProject, ()=>{
                            onPickProject(queueProject);
                        })
                    });
                } else {
                    let project = localStorage.getItem("oa-project");
                    if(!!project && projects.some((p:any) => p.id === project)) {
                        onPickProject(project);
                        return;
                    }
                    if(!!projects && projects.length === 1) {
                        onPickProject(projects[0].id);
                    }
                }
            });

        }
    },[])

    function getProjectLabel(projectId: string | null) {
        let project = projects.find((p:any) => p.id === projectId);
        if(!!project) {
            return project.name + " (" + (project.additionalProjectId ? project.additionalProjectId : project.id) + ")";
        }
        return "?";
    }

    function onPickProject(projectId: string | null) {
        if(!projectId) {
            setErrorDialogWithCloseCallback(translate("internalerror"));
            return;
        }

        let offlineBackboneModel = getBackboneModel(CommonStoreStateKeys.offlineQueue);
        offlineBackboneModel.queue.getSize((size: number) => {
            if(size > 0) {
                offlineBackboneModel.queue.getProject((queueProject: string) => {
                    if(projectId === queueProject) {
                        navigate("/project/"+currentCustomer+"-"+projectId);
                    } else {
                        setDialog({
                            title: translate("warn"),
                            text: translate("offlinequeue.stillnosyncdata").replace("%s", queueProject),
                            buttons: [
                                {
                                    label: translate("yes"),
                                    callback: ()=>setDialog({
                                        title: translate("warn"),
                                        text: translate("offlinequeue.syncdataforeverlostwarn"),
                                        buttons: [
                                            {
                                                label: translate("yes"),
                                                callback: () => {
                                                    offlineBackboneModel.queue.clear();
                                                    setDialog(null);
                                                    navigate("/project/"+currentCustomer+"-"+projectId);
                                                }
                                            },
                                            {
                                                label: translate("no"),
                                                callback: ()=> {
                                                    setDialog(null);
                                                    tryToFindQueueProject(queueProject, ()=>{
                                                        navigate("/project/"+currentCustomer+"-"+queueProject);
                                                    })
                                                }
                                            }
                                        ]
                                    })
                                },
                                {
                                    label: translate("no"),
                                    callback: ()=> {
                                        setDialog(null);
                                        tryToFindQueueProject(queueProject, ()=>{
                                            navigate("/project/"+currentCustomer+"-"+queueProject);
                                        })
                                    }
                                }
                            ]
                        })
                    }
                })
                return;
            } else {
                navigate("/project/" + currentCustomer + "-" + projectId);
            }
        })

    }

    let account = accessibleAccounts.accounts.find((el:any) => el.id === currentCustomer);

    return <PageContainer headerText={translate("projectselection")} newPage={true}>
        <PickProjectPage customer={{name:(!!account && account.label) || currentCustomer, id:currentCustomer}}
                         onChangeCustomer={()=>navigate("/account")}
                         projectIds={projects.filter((p:any) => !p.isArchived).map((p:any) => p.id)}
                         onPickProject={(projectId => onPickProject(projectId))}
                         placeHolder={translate("searchproject")}
                         getProjectLabel={(projectId => getProjectLabel(projectId))}
                         changeString={translate("change")} customerString={translate("customer")}/>
        </PageContainer>
}

export default ProjectSelection;
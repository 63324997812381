
import Box from "@mui/material/Box";
import {translate} from "../../../utils/utils";
import {
    CustomizedInternPaper,
    InternPartTitle,
    SettingsSwitch
} from "../SettingsComponents";
import React from "react";
import {Stack, Switch} from "@mui/material";
import {useCustomDefectAttributesVisibilityHandler} from "./hooks/useCustomDefectAttributesVisibilityHandler";
import {CustomDefectVisibilitySetting} from "./types/types";

type CustomDefectAttributesConfiguratorProps = {
    updateProjectSettings: (settings: CustomDefectVisibilitySetting) => void;
    setRef: (name: string, node: HTMLElement | null | unknown) => void;
    isResetTriggered: boolean;
}

const CustomDefectAttributesConfigurator = ({
                                                updateProjectSettings,
                                                setRef,
                                                isResetTriggered
                                            }: CustomDefectAttributesConfiguratorProps) => {

    const [visible, toggleVisibility] = useCustomDefectAttributesVisibilityHandler(updateProjectSettings, isResetTriggered)

    return (
        <CustomizedInternPaper>
            <Stack aria-details={"notSearchable"} flexDirection={"row"} spacing={1} alignItems={"center"}>
                <InternPartTitle>{`${translate("settings.defectAttributes.title")}`}</InternPartTitle>
            </Stack>
            <Box
                aria-details={`${translate("settings.extendedSettings.title")}-${translate("settings.project.extended.title")}`}
                ref={node => setRef("projectSettingsExtendedDefectSync", node)}>

                <SettingsSwitch control={<Switch checked={visible}
                                                 onChange={toggleVisibility}/>}
                                label={`${translate("settings.defectAttributes.forAn")}`}/>
            </Box>
        </CustomizedInternPaper>

    )
}
export default CustomDefectAttributesConfigurator;
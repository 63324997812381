import React, {useState, useRef} from "react";
import PageContainer, {setDialog} from "../components/PageContainer";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {localStorageItem} from "../utils/constants";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {ProjectPage, SimpleChoiceDialog, StyledButton} from "@bau/material";
import CompressRoundedIcon from '@mui/icons-material/CompressRounded';
import {useNavigate} from "react-router-dom";
// @ts-ignore
import _ from "lodash";
import {dispatchDataWithCallback, getBackboneModel} from "../utils/backboneCommunicationUtils";
import {
    awaitWrap,
    getCustomerProjectWithPrefix,
    isUserAdmin,
    isUserPrincipal,
    isUserReadonly, isUserRestrictedSubcontractor,
    isUserSubcontractor,
    openAnotherTab,
    translate
} from "../utils/utils";
import {clampImageSize, fileToBase64} from "../utils/imageUtils";
import OptionMenu from "../components/OptionMenu";
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ListItemIcon,
    TextField
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {getCurrentProject, postBackupProject, postUploadProjectImage} from "../service/apis";
import {ElementFilter} from "../service/defectsFilter";
import {APP_FUNCTION_NAME, callFunctionInApp, isWebApp} from "../utils/appCommunicationUtils";
import {DocumentMagnifyingGlassIcon, ListBulletIcon, PlusIcon} from "@heroicons/react/24/outline";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {styled} from "@mui/material/styles";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {useAppState} from "../components/AppStateProvider";

const EmailInputField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'lightgray',
        },
        '& fieldset': {
            borderRadius: '.4em',
            border: '1px solid darkgray'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ee7f00',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'darkgray'
    }
});

const Project = () => {

    const navigate = useNavigate();
    let dispatch = useAppDispatch();

    const [backupDialogOpen, setBackupDialogOpen] = useState(false);
    const [reducedDefectListDialogOpen, setReducedDefectListDialogOpen] = useState(false);
    const [email, setEmail] = useState<string|null>(null);

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const userSettings = user.settings;
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);
    const currentInspection = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentInspection]);
    const forcedCustomerProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.forcedCustomerProject]);
    const projects = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.projects]);
    const watchdog = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.watchdog]);

    const { appState } = useAppState();

    const {customerProjectWithInspection} = getCustomerProjectWithPrefix();
    const fileInput = useRef<HTMLInputElement>();

    function getNewDefectLinkLabel() {
        var defectTypeName = "";
        if(!!currentInspection && !!currentInspection.id && !!currentInspection.type) {
            let currentInspectionType = (!!user.inspectionTypeNames && !!user.inspectionTypeNames[currentInspection.type]) ? user.inspectionTypeNames[currentInspection.type] : {};
            if(!!currentInspectionType) {
                let defectType = _.find(currentProject.types, (el:any) => el.defectTypeId === currentInspectionType.defectType)
                defectTypeName = (!!defectType && !!defectType.importExportLabel) ?  defectType.importExportLabel : "";
            }
        }
        return !!defectTypeName ? defectTypeName : translate("defects.newdefectfull");
    }

    function getUpcomingActions() {
        if(isUserReadonly(user) || isUserPrincipal(user)) {
            return null;
        }

        if(!customerProjectWithInspection){
            return null;
        }

        let upcomingActions = [];
        let defectsPagePath = `${customerProjectWithInspection}/defects`;
        let currentDefectsBackboneModel = getBackboneModel(CommonStoreStateKeys.currentDefects);
        let hideCompletedActions = userSettings.hideCompletedActions;

        const setNoSubcontractorFilter = (model:any) => {
            model.resetFilter();
            model.elementFilter.subcontractor = "null";
        }
        setNoSubcontractorFilter(currentDefectsBackboneModel);
        let noSubcontractorCount = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || noSubcontractorCount > 0) {
            let noSubcontractor = {
                amount: noSubcontractorCount,
                name: translate("actions.filter.nosub"),
                onClick: () => {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setNoSubcontractorFilter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(noSubcontractor);
        }

        const setExceedDeadline1Filter = (model:any) => {
            model.resetFilter();
            model.elementFilter.escalationLevels = [ElementFilter.escalation_one];
            model.elementFilter.deadlineFilters = [ElementFilter.deadline_expired];
            model.elementFilter.status = ElementFilter.status_pending;
        }
        setExceedDeadline1Filter(currentDefectsBackboneModel);
        let exceedDeadlineCount = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || exceedDeadlineCount > 0) {
            let exceedDeadline1 = {
                amount: exceedDeadlineCount,
                name: translate("overview.filter.overdue1"),
                onClick: ()=>{
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setExceedDeadline1Filter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(exceedDeadline1);
        }

        const setExceedDeadline2Filter = (model:any) => {
            model.resetFilter();
            model.elementFilter.escalationLevels = [ElementFilter.escalation_two, ElementFilter.escalation_three];
            model.elementFilter.deadlineFilters = [ElementFilter.deadline_expired];
            model.elementFilter.status = ElementFilter.status_pending;
        }
        setExceedDeadline2Filter(currentDefectsBackboneModel);
        let exceedDeadline2Count = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || exceedDeadline2Count > 0) {
            let exceedDeadline2 = {
                amount: exceedDeadline2Count,
                name: translate("overview.filter.overdue2"),
                onClick: () => {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setExceedDeadline2Filter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(exceedDeadline2);
        }

        const setSubcontractorChangedFilter = (model:any) => {
            model.resetFilter();
            model.elementFilter.allSubChanges = true;
        }
        setSubcontractorChangedFilter(currentDefectsBackboneModel);
        let subcontractorChangeCount = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || subcontractorChangeCount > 0) {
            let subcontractorChangedAction = {
                amount: subcontractorChangeCount,
                name: translate("actions.filter.subcontractor-fixed"),
                onClick: () => {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setSubcontractorChangedFilter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(subcontractorChangedAction);
        }

        const setSolvedRejectedFilter = (model:any) => {
            model.resetFilter();
            model.elementFilter.principalStatus = ElementFilter.principalStatus_rejected;
            model.elementFilter.status = ElementFilter.status_fixed;
            model.elementFilter.extraStatus = ElementFilter.extraStatus_P;
        }
        setSolvedRejectedFilter(currentDefectsBackboneModel);
        let solvedRejectedCount = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || solvedRejectedCount) {
            let solvedRejected = {
                amount: solvedRejectedCount,
                name: translate("actions.filter.solvedrejected"),
                onClick: () => {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setSolvedRejectedFilter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(solvedRejected);
        }

        const setPrincipalChangedFilter = (model:any) => {
            model.resetFilter();
            model.elementFilter.allPrincipalChanges = true;
        }
        setPrincipalChangedFilter(currentDefectsBackboneModel);
        let principalChangeCount = currentDefectsBackboneModel.filtered().length;
        if (!hideCompletedActions || principalChangeCount > 0) {
            let principalChangedAction = {
                amount: principalChangeCount,
                name: translate("actions.filter.principalchanges"),
                onClick: () => {
                    let model = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    setPrincipalChangedFilter(model);
                    navigate(defectsPagePath);
                }
            }
            upcomingActions.push(principalChangedAction);
        }

        currentDefectsBackboneModel.resetFilter();

        return upcomingActions;
    }

    function getDirectLinks() {
        if(!customerProjectWithInspection){
            return null;
        }

        const subcontractor = isUserSubcontractor(user);
        const principal = isUserPrincipal(user);
        const readOnly = isUserReadonly(user);
        const restrictedSubcontractor = isUserRestrictedSubcontractor(user);

        let goToDefectList = {
            icon: <ListBulletIcon/>,
            name: translate("overview.directlinks.alldefects"),
            onClick: ()=>{
                let currentDefectsBackboneModel = getBackboneModel(CommonStoreStateKeys.currentDefects);
                currentDefectsBackboneModel.resetFilter();
                localStorage.removeItem("defects_filter");
                navigate(`${customerProjectWithInspection}/defects`);
            },
        }
        if(subcontractor && !restrictedSubcontractor){
            let requestsList = {
                icon: <DocumentMagnifyingGlassIcon/>,
                name: translate("overview.directlinks.unreadRequests"),
                onClick: ()=>navigate(`${customerProjectWithInspection}/documents`),
            }
            let pendingSubcontractor = {
                icon: <ListBulletIcon/>,
                name: translate("overview.directlinks.opendefects"),
                onClick: ()=>{
                    let currentDefectsBackboneModel = getBackboneModel(CommonStoreStateKeys.currentDefects);
                    currentDefectsBackboneModel.resetFilter();
                    currentDefectsBackboneModel.elementFilter.status = ElementFilter.status_subcontractorPending;
                    navigate(`${customerProjectWithInspection}/defects`)
                },
            }
            return [pendingSubcontractor, goToDefectList, requestsList].filter(e=>!!e);
        } else {
            let newDefect = readOnly ? undefined : {
                icon: <PlusIcon/>,
                name: getNewDefectLinkLabel(),
                onClick: ()=>navigate(`${customerProjectWithInspection}/defect/c`),
            }
            let unsentRequests = (readOnly || principal) ? undefined : {
                icon: <DocumentMagnifyingGlassIcon/>,
                name: translate("actions.action.unsent"),
                onClick: () => {
                    navigate(`${customerProjectWithInspection}/subcontractors`, {state:{requestBundle:true}});
                },
            }
            return [newDefect, unsentRequests, goToDefectList].filter(e => !!e);
        }
    }
    
    async function backupProject() {
        let [e,] = await awaitWrap(postBackupProject(currentProject.id, email!));
        setDialog({
            title: !e? translate("success") : translate("error"),
            text: !e ? translate("backupsuccess") : translate("backupfailed"),
            buttons: [
                {
                    label: translate("ok"),
                     callback: ()=>setDialog(null)
                }
            ]
        });
    }

    async function uploadProjectImage(event: React.ChangeEvent<HTMLInputElement>) {
        const customerId = localStorage.getItem(localStorageItem.OA_CUSTOMER);
        const projectId = localStorage.getItem(localStorageItem.OA_PROJECT);

        if (customerId && projectId && event.target.files && event.target.files.length === 1) {
            let [, image] = await awaitWrap(clampImageSize(event.target.files[0], 1024));
            event.target.value = ''; // reset file input

            if (image) {
                let [, imageData] = await awaitWrap(fileToBase64(image));

                if (imageData) {
                    let [e,] = await awaitWrap(postUploadProjectImage(customerId, projectId, imageData));

                    if (!e) {
                        // set project image
                        let reloadedProject = (await getCurrentProject(projectId)).data;
                        dispatchDataWithCallback(true,  CommonStoreStateKeys.currentProject, reloadedProject, dataFromBackbone => {
                            dispatch(setStateValue({key: CommonStoreStateKeys.currentProject, value: dataFromBackbone}));
                        });
                        return;
                    }
                }
            }
        }
        // error dialog
        setDialog({
            title: translate("error"),
            text: translate("overview.setprojectimage.error"),
            buttons: [
                {
                    label: translate("ok"),
                    callback: () => setDialog(null)
                }
            ]
        });
    }

    function getRight() {
        let res = [];
        if(!forcedCustomerProject && watchdog.connected && projects.length > 1){
            res.push(<StyledButton key={'changeProject'} onClick={()=> navigate(`/projects`)}>
                {translate("changeproject")}
            </StyledButton>)
        }
        let attachmentsSyncAvailable = !!watchdog.attchmentsInSync && watchdog.attchmentsInSync.project === currentProject.id && !watchdog.attchmentsInSync.value;
        let plansSyncAvailable = !!watchdog.plansInSync && watchdog.plansInSync.project === currentProject.id && !watchdog.plansInSync.value;
        let admin = isUserAdmin(user);
        let readonly = isUserReadonly(user);
        res.push(
            <OptionMenu key={'optionMenu'}>
                {!readonly && <Box>
                    <MenuItem onClick={() => {fileInput.current?.click()}}>
                        <ListItemIcon>
                            <FileUploadIcon fontSize="small"/>
                        </ListItemIcon>
                        {translate("overview.setprojectimage")}
                    </MenuItem>
                </Box>}
                {admin && <Box>
                    <MenuItem onClick={() => setBackupDialogOpen(true)}>
                        <ListItemIcon>
                            <CloudDownloadIcon fontSize="small"/>
                        </ListItemIcon>
                        {translate("projectbackup")}
                    </MenuItem>
                    {isWebApp && <Box>
                        <Divider/>
                        <MenuItem onClick={() => openAnotherTab("/api/project-stats", translate("download"))}>
                            <ListItemIcon>
                                <DescriptionOutlinedIcon fontSize="small"/>
                            </ListItemIcon>
                            {translate("downloadprojectsstats")}
                        </MenuItem>
                    </Box>}
                </Box>}
                {(!isWebApp && (attachmentsSyncAvailable || plansSyncAvailable)) && <Box>
                    {admin && <Divider/>}
                    {plansSyncAvailable && <MenuItem onClick={() => {
                        callFunctionInApp(APP_FUNCTION_NAME.SYNC_PLANS, {projectId: currentProject.id});
                    }}>
                        <ListItemIcon>
                            <MapOutlinedIcon fontSize="small"/>
                        </ListItemIcon>
                        {translate("overview.plans.sync")}
                    </MenuItem>}
                    {attachmentsSyncAvailable && <MenuItem onClick={() => {
                        callFunctionInApp(APP_FUNCTION_NAME.SYNC_ATTACHMENTS, {projectId: currentProject.id});
                    }}>
                        <ListItemIcon>
                            <ImageOutlinedIcon fontSize="small"/>
                        </ListItemIcon>
                        {translate("overview.defect.images.sync")}
                    </MenuItem>}
                </Box>}
                <Box>
                    {(!readonly || (!isWebApp && (attachmentsSyncAvailable || plansSyncAvailable))) && <Divider/>}
                    <MenuItem onClick={() => {
                        dispatch(setStateValue({key: CommonStoreStateKeys.currentCustomer, value: ""}));
                        navigate("/");
                    }}>
                        <ListItemIcon>
                            <RefreshIcon fontSize="small"/>
                        </ListItemIcon>
                        {translate("overview.reloadproject")}
                    </MenuItem>
                </Box>
            </OptionMenu>
        )
        return res.length > 0 ? res : undefined;
    }

    const closeBackupDialog = ()=>{
        setBackupDialogOpen(false);
        setEmail(null);
    }

    let groupNote = translate('info.groupNote').replace('%', currentProject.groupName);
    let directLinksString = translate("serviceName");
    if(currentProject.groupFilterActive) {
        directLinksString = `${directLinksString} (${groupNote})`
    }

    const headerText = currentProject.name + " (" + (currentProject.additionalProjectId ? currentProject.additionalProjectId : currentProject.id) + ")";

    return <PageContainer headerText={headerText}
                          right={getRight()}
                          newPage={true}>
        <ProjectPage
            projectImageUrl={currentProject.info.image}
            upcomingActions={getUpcomingActions()}
            // @ts-ignore
            directLinks={getDirectLinks()}
            everythingDoneString={translate("overview.actions.everythingDone")}
            upcomingActionString={
                <div className={"overview-actions-title"}>{translate("overview.actions.title")}
                    { appState.usingReducedDefectList && <span className={"overview-info-button"} onClick={() => {
                        setReducedDefectListDialogOpen(true);
                    }}>
                        &#9432;
                    </span>}
                </div>

            }
            directLinksString={directLinksString}
        />
        <Input
            type={'file'}
            style={{display: 'none'}}
            inputRef={fileInput}
            inputProps={{accept: "image/jpeg"}}
            onChange={uploadProjectImage}
        />
        <Dialog open={backupDialogOpen} onClose={closeBackupDialog}>
            <DialogTitle>
                {translate("overview.backup.info").replace('%s', currentProject.name)}
            </DialogTitle>
            <DialogContent style={{paddingTop: "1em"}}>
                <EmailInputField sx={{width: "100%"}} label={translate("overview.backup.email")} value={email||""} onChange={(e)=>setEmail(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeBackupDialog}>{translate("cancel")}</Button>
                <Button onClick={()=>{
                    closeBackupDialog();
                    backupProject();
                }}>{translate("ok")}</Button>
            </DialogActions>
        </Dialog>
        <SimpleChoiceDialog open={reducedDefectListDialogOpen}
                            title={translate('overview.reducedDefectListDialog.title')}
                            text={(() => {
                                const str: string = translate('reduceddefects.msg');
                                const parts = str.split('%%iconWrapped');
                                return (<div>
                                    <span>{parts[0]}</span>
                                    <CompressRoundedIcon className="reduced-defect-list-btn"/>
                                    <span>{parts[1]}</span>
                                </div>) as unknown as string; //TODO: wait for bau-material 0.4.2-SNAPSHOT-49  or later and remove this
                            })()}
                            buttons={
                                [
                                    {
                                        label: translate('ok'),
                                        callback: () => {
                                            setReducedDefectListDialogOpen(false);
                                        }
                                    }
                                ]
                            }/>
    </PageContainer>
}

export default Project;
import {DefectSyncTextField} from "../SettingsComponents";
import {translate} from "../../../utils/utils";
import React from "react";
import {Button, FormControl, Grid, InputLabel, Select, Stack} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Subcontractor, SubcontractorSync} from "./types/types";

type SubcontractorSyncConfigurationProps = {
    defectSyncToSubcontractorData: SubcontractorSync;
    updateDefectSyncToSubcontractorData: (arg?: string, arg2?: string, arg3?: string) => void;
    deleteSubcontractorSync: (arg: string) => void;
    subcontractors: Subcontractor[];
};

const SubcontractorSyncConfiguration = ({defectSyncToSubcontractorData, updateDefectSyncToSubcontractorData, subcontractors, deleteSubcontractorSync}: SubcontractorSyncConfigurationProps) => {

    return(
        <Stack spacing={1} sx={{marginTop: '.3rem'}}>
            {defectSyncToSubcontractorData.map((data) => {
                return <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <FormControl variant="filled">
                            <InputLabel>{translate("settings.defectsync.selectasubcontractor")}</InputLabel>
                            <Select
                                onChange={e => updateDefectSyncToSubcontractorData(data.subcontractorId, e.target.value)}
                                value={data.subcontractorId}
                            >
                                {
                                    subcontractors.filter((subcontractor: Subcontractor) => defectSyncToSubcontractorData.map(el => el.subcontractorId).every((ss: string) => ss !== subcontractor.id || ss === data.subcontractorId))
                                        .map((subcontractor: Subcontractor) => {
                                            return <MenuItem key={subcontractor.id}
                                                             value={subcontractor.id}>{subcontractor.name}</MenuItem>
                                        })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <DefectSyncTextField
                            onChange={e => updateDefectSyncToSubcontractorData(data.subcontractorId, data.subcontractorId, e.target.value)}
                            variant="filled"
                            placeholder={translate("settings.defectsync.entercustomerproject")}
                            value={data.defectSyncText}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Button sx={{padding: '16px 8px'}}
                                onClick={() => deleteSubcontractorSync(data.subcontractorId)}
                                variant={"contained"}>{translate("delete")}</Button>
                    </Grid>
                </Grid>
            })}
            {(defectSyncToSubcontractorData.length < subcontractors.length) &&
                <Button onClick={() => updateDefectSyncToSubcontractorData()}
                        variant="contained">{translate("settings.defectsync.addanothersynctosubcontractor")}</Button>}
        </Stack>
    )
}

export default SubcontractorSyncConfiguration;
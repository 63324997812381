import {page, pageScrollData} from "./constants";
// @ts-ignore
import _ from "lodash";
import store from "../store/store";
import {CommonStoreStateKeys, setStateValue} from "../store/slices/commonStoreSlice";
import {setDialog, setSnackbar} from "../components/PageContainer";
import {APP_FUNCTION_NAME, callFunctionInApp} from "./appCommunicationUtils";
// @ts-ignore
import Cookies from "js-cookie";
import {USER_AUTHORITIES} from "../pages/settings/SettingsEnums";

let translations:any;
let scrollData:pageScrollData = {top:0, scrolledPage:page.NONE};


export function setTranslations(value: any) {
    translations = value;
}

export function setHtmlLanguageTag(user : any){
    document.documentElement.lang =  !!user ? user.data.settings.lang.toLowerCase() : 'de';
    document.documentElement.translate= false;
}

export function awaitWrap<T, U = any>(promise: Promise<T>): Promise<[U | null, T | null]> {
    return promise
        .then<[null, T]>((data: T) => [null, data])
        .catch<[U, null]>(err => [err, null])
}

export function updateCookie() {
    let cookie = Cookies.get("ox.auth.token");
    let jSession = Cookies.get("JSESSIONID");
    callFunctionInApp(APP_FUNCTION_NAME.SAVE_LOGIN_COOKIE, {cookie: `ox.auth.token=${cookie};JSESSIONID=${jSession}`});
}

export function isShareToken(user: any): boolean {
    return _.intersection(user.authorities, ["ROLE_TOKEN"]).length > 0;
}

export function isUserRegistered(user: any): boolean {
    return _.intersection(user.authorities, [USER_AUTHORITIES.ROLE_SUBCONTRACTOR_READONLY]).length === 0;
}

export function isUserRestrictedSubcontractor(user: any): boolean {
    return _.intersection(user.authorities, [USER_AUTHORITIES.ROLE_SUBCONTRACTOR_READONLY]).length > 0;
}

export function isUserSubcontractor(user: any): boolean {
    return _.intersection(user.authorities, [USER_AUTHORITIES.ROLE_SUBCONTRACTOR, USER_AUTHORITIES.ROLE_SUBCONTRACTOR_READONLY]).length > 0;
}

export function isUserPrincipal(user: any): boolean {
    return _.intersection(user.authorities, [ USER_AUTHORITIES.ROLE_PRINCIPAL, USER_AUTHORITIES.ROLE_PRINCIPAL_READONLY ]).length > 0;
}

export function isUserReadonly(user: any): boolean {
    return _.intersection(user.authorities, [ USER_AUTHORITIES.ROLE_SUBCONTRACTOR, USER_AUTHORITIES.ROLE_SUBCONTRACTOR_READONLY, USER_AUTHORITIES.ROLE_PRINCIPAL_READONLY, USER_AUTHORITIES.ROLE_READONLY ]).length > 0;
}

export function isUserAdmin(user: any): boolean {
    return _.intersection(user.authorities, [ USER_AUTHORITIES.ROLE_ADMIN, USER_AUTHORITIES.ROLE_PROJECT_ADMIN ]).length > 0;
}

export function isUserProjectAdmin(user: any): boolean {
    return _.intersection(user.authorities, [USER_AUTHORITIES.ROLE_PROJECT_ADMIN ]).length > 0;
}

export function getProjectIdFromCustomerProject(customerProject?: string, split?:string) {
    let split_copy = !!split ? split : "-";
    if(!customerProject) {
        return "";
    }
    let parts = customerProject.split(split_copy);
    if(parts.length < 2) {
        return "";
    }
    parts.splice(0,1);
    return parts.join(split_copy);
}

export function getCustomerIdFromCustomerProject(customerProject?: string, split?:string) {
    if(!customerProject) {
        return "";
    }
    let parts = customerProject.split(!!split ? split : "-");
    if(parts.length < 2) {
        return "";
    }
    return parts[0];
}

export function getCustomerProjectWithPrefix(): {customerProject: string, customerProjectWithInspection: string}{
    let res = {customerProject: "", customerProjectWithInspection: ""};
    let state = store.getState();
    let currentCustomer = state.commonReducer[CommonStoreStateKeys.currentCustomer];
    let currentProject = state.commonReducer[CommonStoreStateKeys.currentProject];
    let currentInspection = state.commonReducer[CommonStoreStateKeys.currentInspection];
    if(!!currentCustomer && !!currentProject && !!currentProject.id) {
        res.customerProject = `/project/${currentCustomer}-${currentProject.id}`;
        res.customerProjectWithInspection = res.customerProject;
        if(!!currentInspection && !!currentInspection.id) {
            res.customerProjectWithInspection = res.customerProjectWithInspection + '/inspection/' + currentInspection.id;
        }
    }
    return res;
}

export function translate(key:string) {
    return !!translations && !!translations[key] ? translations[key] : key;
}

export function saveLastSavedScrollTop(data?:pageScrollData) {
    if(!data) {
        scrollData = {top: 0, scrolledPage: page.NONE}
    } else {
        scrollData = data;
    }
}

export function getLastSavedScrollTop() {
    return scrollData;
}


export function textNodesUnder(el:any, searchValue:string) {
    const children = [] // Type: Node[]
    const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT)
    while(walker.nextNode()) {
        children.push(walker.currentNode)
    }

    return  children.map(el => el.nodeValue);
}

export function editableNodesUnder(el:any) {
    const children = [] // Type: Node[]
    const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT)
    while(walker.nextNode()) {
        children.push(walker.currentNode)
    }

    return  children.map(el => el.nodeValue);
}

export function setErrorDialogWithCloseCallback(msg: string) {
    setDialog({
        title: translate("error"),
        text: msg,
        buttons: [
            {
                label: translate("ok"),
                callback: () => setDialog(null)
            }
        ]
    })
}

export function setSuccessDialogWithCloseCallback(msg: string) {
    setDialog({
        title: translate("notice"),
        text: msg,
        buttons: [
            {
                label: translate("ok"),
                callback: () => setDialog(null)
            }
        ]
    })
}

export async function downloadWrapper(download: any) {
    store.dispatch(setStateValue({
        key: CommonStoreStateKeys.loading,
        value: true
    }))

    let [e,r] = await awaitWrap(download());
    store.dispatch(setStateValue({
        key: CommonStoreStateKeys.loading,
        value: false
    }))

    if(!!e) {
        setErrorDialogWithCloseCallback(translate("downloadfailed"));
    }
    return [e, r];
}

export const copyToClipboard = async (text: string) => {
    let [e,r] = await awaitWrap(navigator.clipboard.writeText(text));
    setSnackbar({
        severity: !!e ? 'error' : "success",
        duration: 1800,
        message: translate(!!e ? 'copyfailed' : "copysuccessful"),
        onClose: ()=>setSnackbar(null)
    })
    return !e;
}

export const openAnotherTab = (location: string, windowTitle?:string) => {
    let newWindow = window.open(`${window.location.origin}/onlineBauabnahme${location}`);
    if(!!windowTitle && !!newWindow) {
        newWindow.document.title = windowTitle;
    }
}

import {disableBeforeUnloadEvent} from "../utils/backboneCommunicationUtils";

export class NewUiService {
    static JSVERSION_KEY = 'jsversion';
    static JSVERSION_VALUE_TEST = 'test';
    static JSVERSION_VALUE_PRODUCTION = 'production';

    static checkForAutoSwitching(customer: string) {
        if (!NewUiService.isJsVersionSet()) {
            //nothing set yet. Activate new UI for ox and implenia swiss
            if (customer === 'ox' || customer === 'implenia_swiss') {
                NewUiService.switchToTestVersion();
            }
        }

    }

    static switchToTestVersion() {
        NewUiService.setCurrentJsVersion(NewUiService.JSVERSION_VALUE_TEST);
        disableBeforeUnloadEvent();
        window.location.reload();
    }

    static switchToProductionVersion() {
        NewUiService.setCurrentJsVersion(NewUiService.JSVERSION_VALUE_PRODUCTION);
        disableBeforeUnloadEvent();
        window.location.reload();
    }

    static isJsVersionSet(): boolean {
        const currentJsVersion = sessionStorage.getItem(NewUiService.JSVERSION_KEY);
        return !!currentJsVersion;
    }

    static getCurrentJsVersion(): string | null {
        const currentJsVersion = sessionStorage.getItem(NewUiService.JSVERSION_KEY);
        return currentJsVersion;
    }

    static setCurrentJsVersion(version: string): void {
        sessionStorage.setItem(NewUiService.JSVERSION_KEY, version);
    }

}
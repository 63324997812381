import {CenterContainer, FullPage} from "../components/styledComponents";
import {Box, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const RequestShareErrorPage = () => {
    const navigate = useNavigate();

    const goToHomepage = ()=>{
        navigate('/');
    }

    return <FullPage>
        <CenterContainer>
            <Stack flexDirection={'column'} spacing={3}>
                <Typography variant={'h1'}>Uuups!</Typography>
                <Typography variant={'h3'}>Der aufgerufene Schnellzugang existiert nicht. Bitte wenden Sie sich an den Projektleiter!</Typography>
                <Box>
                    Zurück zur <a className='clickable' onClick={goToHomepage}>Startseite</a>?
                </Box>
            </Stack>

        </CenterContainer>
    </FullPage>
}

export default RequestShareErrorPage;

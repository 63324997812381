import {FormControl, InputLabel, Select} from "@mui/material";
import {translate} from "../../../utils/utils";
import {DEFECT_SYNC_DIRECTION} from "../SettingsEnums";
import MenuItem from "@mui/material/MenuItem";
import React from "react";


type SyncDirectionControlProps = {
    defectSyncDirection: DEFECT_SYNC_DIRECTION;
    handleDirectionChange: (newDirection:DEFECT_SYNC_DIRECTION) => void;
};

const SyncDirectionControl = ({defectSyncDirection, handleDirectionChange}: SyncDirectionControlProps) => {

    return(
        <FormControl variant="filled">
            <InputLabel>{translate("settings.defectSync.title")}</InputLabel>
            <Select
                onChange={e => {
                    handleDirectionChange(e.target.value as DEFECT_SYNC_DIRECTION)
                }}
                value={defectSyncDirection}
            >
                {
                    [DEFECT_SYNC_DIRECTION.TO_PRINCIPAL, DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR]
                        .map(direction => {
                            return <MenuItem key={direction}
                                             value={direction}>{DEFECT_SYNC_DIRECTION.getDescription(direction)}</MenuItem>
                        })
                }
            </Select>
        </FormControl>
    )
}

export default SyncDirectionControl;
import {useEffect, useState} from "react";
import {DEFECT_SYNC_DIRECTION} from "../../SettingsEnums";
import {Subcontractor} from "../types/types";
import {getSubcontractorData} from "../api/subcontractorData";


export const useChangeSyncDirectionHandler = (
    isResetTriggered: boolean
) => {

    const [subcontractors, setSubcontractors] = useState<Subcontractor[]>([])
    const [direction, setDirection] = useState<DEFECT_SYNC_DIRECTION>(DEFECT_SYNC_DIRECTION.TO_PRINCIPAL)

    useEffect(() => {
        getSubcontractorData().then((res) => {
            setSubcontractors(res.data.data)
        });
    }, []);

    useEffect(():void => {
        if (subcontractors.some((subcontractor: Subcontractor):boolean => !!subcontractor.defectSync)) {
            setDirection(DEFECT_SYNC_DIRECTION.TO_SUBCONTRACTOR)
        }else {
            setDirection(DEFECT_SYNC_DIRECTION.TO_PRINCIPAL)
        }
    }, [subcontractors, isResetTriggered]);

    const handleDirectionChange = (value: DEFECT_SYNC_DIRECTION) => {
        setDirection(value)
    };

    return [direction, handleDirectionChange] as const;
};
import {useEffect, useState} from "react";

import {CustomDefectVisibilitySetting} from "../types/types";
import {useAppSelector} from "../../../../store/hooks";
import {CommonStoreStateKeys} from "../../../../store/slices/commonStoreSlice";


export const useCustomDefectAttributesVisibilityHandler = (
    updateProjectSettings: (settings: CustomDefectVisibilitySetting) => void,
    resetTriggered:boolean
) => {

    const [visible, setVisibility] = useState<boolean>(false)

    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);

    console.log(currentProject)
    useEffect(():void => {
        setVisibility(!!currentProject.showCustomDefectAttributesToSubcontractor)
    }, [currentProject, resetTriggered]);


    const toggleVisibility = () => {
        const newVisibility = !visible
        setVisibility(newVisibility)
        updateProjectSettings({showCustomDefectAttributesToSubcontractor: newVisibility})
    };


    return [visible, toggleVisibility] as const;
};
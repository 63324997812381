import {useEffect, useState} from "react";
import {CommonStoreStateKeys} from "../../../../store/slices/commonStoreSlice";
import {useAppSelector} from "../../../../store/hooks";
import {PrincipalSyncConfigurationType, SubcontractorSyncConfigurationType} from "../types/types";

export const usePrincipalDataHandler = (
    updateProjectSettings: (settings: PrincipalSyncConfigurationType | SubcontractorSyncConfigurationType) => void,
    isResetTriggered: boolean
) => {

    const [principalData, setPrincipalData] = useState<string>("")
    const currentProject = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentProject]);

    useEffect(():void => {
        setPrincipalData(currentProject.defectSync)
    }, [currentProject, isResetTriggered]);

    const handlePrincipalDataChange = (value: string) => {
        setPrincipalData(value)
        updateProjectSettings({defectSync: value})
    };

    return [principalData, handlePrincipalDataChange] as const;
};
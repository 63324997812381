import {translate} from "../../../utils/utils";
import {TemplateEditField} from "../SettingsComponents";
import React from "react";


type PrincipalSyncConfigurationProps = {
    defectSyncToPrincipalData: string;
    setDefectSyncToPrincipalData: (arg: string) => void;
};

const PrincipalSyncConfiguration = ({defectSyncToPrincipalData, setDefectSyncToPrincipalData}: PrincipalSyncConfigurationProps) => {

    return(
        <TemplateEditField
            sx={{marginTop: '.3rem'}}
            value={defectSyncToPrincipalData}
            onChange={e => setDefectSyncToPrincipalData(e.target.value)}
            variant="filled"
            placeholder={translate("settings.defectsync.entercustomerproject")}
        />
    )
}

export default PrincipalSyncConfiguration;